import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:localStorage.getItem("token"),
    userInfo: JSON.parse(localStorage.getItem("userInfo")),
    routes:[],
  },
  getters: {
    //get this.$store.getters.getUser
    getUser: state => {
      return state.userInfo
    },
    getToken: state => {
      return state.token
    }
  },
  mutations: {
    //set
    SET_TOKEN: (state, token) =>{
      state:token = token
      localStorage.setItem("token", token)
    },
    SET_USERINFO: (state, userInfo) =>{
      state:userInfo = userInfo
      localStorage.setItem("userInfo", JSON.stringify(userInfo))
      // sessionStorage.setItem("userInfo", JSON.stringify(userInfo))
    },
    SET_TOKEN_NONE: (state, token) =>{
      state:token = ''
      localStorage.setItem("token", null)
    },
    SET_USERINFO_NONE: (state, userInfo) =>{
      state:userInfo = {}
      localStorage.setItem("userInfo", JSON.stringify(''))
      // sessionStorage.setItem("userInfo", JSON.stringify(''))
    },
    initRoutes(state,data){
      state.routes = data
    }
  },
  actions: {

  },
  modules: {

  },
})
