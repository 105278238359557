<template >
  <router-view></router-view>
</template>

<script>
import DevicePixelRatio from './utils/devicePixelRatio';
export default {
  data() {
    return {

    }
  },
  created() {
    new DevicePixelRatio().init();
  },
  methods:{

  }
}
</script>
<style>
.ellipsis{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ellipsisTwo{
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ellipsisThree{
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
  /*把最外面的html，body设置为100%就好*/
  html,
  #app,
  body {
    height: 100%;
    min-width: 900px;
    margin: 0;
    padding: 0;
  }
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 14px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #dddee0;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: none;
  }
</style>
