import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "首页",
    icon: "",
    component: () => import("@/views/Main.vue"),
    children: [
      {path: "/", component: () => import("@/views/Home")},
      //首页
      {path: "/Home", component: () => import("@/views/Home")},
      //新闻
      {path: '/news', component: () => import('@/views/news/index.vue')},
      // 登录路由
      {path: '/login', component: () => import('@/views/Login/index.vue')},
      // 注册路由
      {path: '/register', component: () => import('@/views/register/index.vue')},
      //作品列表
      {path: '/work', component: () => import('../views/work/index.vue')},
      // 作品详情
      // {path: '/workInfo/:id', component: () => import('../views/work/content.vue')},
      // 维护路由
      {path: '/error', component: () => import('@/views/error/404.vue')},
      // 新闻，置顶新闻，公告，等的详情页面（三级页面）
      {path: '/newsContent/:id', component: () => import('@/views/Home/newsContent.vue')},
    ],
  },
  //404
  {path: '*', component: () => import('../views/error/404.vue')},
  // //404
  // {path: "*",component: () => import("../components/404")},
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.afterEach(route => {
  // 从路由的元信息中获取 title 属性
  if (route.meta.title) {
    document.title = route.meta.title;
    // 如果是 iOS 设备，则使用如下 hack 的写法实现页面标题的更新
    if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      const hackIframe = document.createElement('iframe');
      hackIframe.style.display = 'none';
      hackIframe.src = '/static/html/fixIosTitle.html?r=' + Math.random();
      document.body.appendChild(hackIframe);
      setTimeout(_ => {
        document.body.removeChild(hackIframe)
      }, 300)
    }
  }
});

const originPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originPush.call(this, location).catch(err => err)
}

//获取开始时间
let startTime = Date.now();
//定义一个获取用户的函数
const get_userinfo = (to, from, token) => {
//获取当前时间
  let currentTime = Date.now();
  let Brow_history = {
    preUrl: from.path,
    preTitle: from.meta.title,
    currentUrl: to.path,
    currentTitle: to.meta.title,
    prePage_browTime: parseInt((currentTime - startTime) / 1000) + "s",
  };
  if(token){
    Brow_history.user = JSON.parse(sessionStorage.getItem("user"));
  }
  // console.log(Brow_history);
  // console.log(
  //   "用户由 ",
  //   from.path,
  //   " 页面 跳转到 ",
  //   to.path,
  //   " 页面，在 ",
  //   from.path,
  //   " 页面停留了 ",
  //   currentTime - startTime,
  //   "毫秒。转换成秒数约为：",
  //   parseInt((currentTime - startTime) / 1000)
  // );
  //初始化开始时间
  startTime = Date.now();
  // console.log("======== 分割线 ========");
};

router.beforeEach((to, from, next) => {
  // get_userinfo(to, from);
  // next();
  // NProgress.start();
  if (to.meta.title) {
    //判断是否有标题
    document.title = to.meta.title;
  }
  if (to.meta.requireAuth == true) {
    const token = getToken();
    //需要登录权限进入的路由
    if (token) {
    //调用刚才定义的函数
      get_userinfo(to, from, token);
      next();
    } else {
      Message.error("未登录");
      next({ name: "login" });
    }
  } else {
    get_userinfo(to, from);
    //不需要登录权限
    next();
  }
  next();
});


export default router
