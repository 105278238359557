import Vue from "vue";
import Loading from "./index.vue"

let $app = Vue.extend(Loading)

let $Loading = new $app().$mount(document.createElement('div'))

document.body.appendChild($Loading.$el)

export default{
    install(vm){
        vm.prototype.$Loading = {
            show:()=>{
                $Loading.visable = true
            },
            hide:()=>{
                $Loading.visable = false
            }
        }
    }
}