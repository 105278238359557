<template>
  <transition name="animation">
    <div v-if="visable" class="loading"></div>
  </transition>
</template>
 
<script>
export default {
  name: "Loading", // 定义的组件名称 使用时写法：loading
  data(){
      return{
          visable:false,
      }
  }
};
</script>
 
<style scoped>
.animation-enter,
.animation-leave-to {
  opacity: 0;
}
.animation-enter-active,
.animation-leave-active {
  transition: opacity 0.6s;
}
.loading {
  position: fixed;
  left: 0;
  top: 0;
  /* background: url("../../assets/Loading.gif")
    center center no-repeat #fff; */
  background: url("https://boke-yaobale.obs.cn-east-3.myhuaweicloud.com/boke_img/Loading.gif")
    center center no-repeat #fff;
  background-size: 25%;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}
</style>
 